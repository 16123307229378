import React, { Component }  from 'react';
import profile from '../assets/images/profile.svg'
import favorite from '../assets/images/favorite.svg'
import savedsearches from '../assets/images/saved-searches.svg'
import yourhome from '../assets/images/your-home.svg'
import notification from '../assets/images/notification.svg'
import messages from '../assets/images/messages.svg'
import logout from '../assets/images/logout.svg'
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from "../actions/authActions";
import  { Navigate,useNavigate } from 'react-router-dom'

const Sidebar = () => {

    const dispatch = useDispatch();
    const { logout: funcLogout } = bindActionCreators( authActions, dispatch );
    const navigate = useNavigate();

    const onLogout = async (e) => {
        await funcLogout().then((res) => {
            navigate('/login');
        });
    };

    return (
        <div className="left_sidebar collapse" id="collapseExample">
            <div className="sidebar_menu">
                <ul>
                    <li className="active">
                        <Link to="/">
                            <img src={profile}/> 
                            Profile
                        </Link> 
                    </li>
                    <li>
                        <Link to="/favourites">
                            <img src={favorite} /> 
                            Favourites
                        </Link> 
                    </li>
                    <li>
                        <Link to="/saved-search">
                            <img src={savedsearches} /> 
                            Saved Searches
                        </Link> 
                    </li>
                    <li>
                        <Link to="/your-home">
                            <img src={yourhome} /> 
                            Your Home
                        </Link> 
                    </li>
                    <li>
                        <Link to="/notification">
                            <img src={notification} /> 
                            Notifications
                        </Link> 
                    </li>
                    <li>
                        <Link to="/messages">
                            <img src={messages} /> 
                            Messages <span className="ml-3 badge badge-danger">3</span>
                        </Link> 
                    </li>
                    <li>
                        <Link to="/" onClick = { (e) => onLogout(e) }>
                            <img src={logout} /> 
                            Log Out
                        </Link> 
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar
