import React, { useEffect, useState }  from 'react';
import Header from './header'
import Footer from './footer'
import profile from '../assets/images/profile.svg'
import favorite from '../assets/images/favorite.svg'
import savedsearches from '../assets/images/saved-searches.svg'
import yourhome from '../assets/images/your-home.svg'
import notification from '../assets/images/notification.svg'
import messages from '../assets/images/messages.svg'
import logout from '../assets/images/logout.svg'
import menu from '../assets/images/menu.png'
import email from '../assets/images/email.svg'
import print from '../assets/images/print.svg'
import homeimage from '../assets/images/user-homeimg.png'
import eyeicon from '../assets/images/eyes-icon.svg'
import Sidebar from './sidebar.js'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  checkRequiredValidationWithMinMax,
  checkUserValidation,
  checkEmptyValidation
} from "../Helpers/Validation";
import * as authActions from "../actions/authActions";
import  { Navigate,useNavigate } from 'react-router-dom'

import {
  Form,
  Input,
} from "reactstrap";

const SignUp = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [state, setState] = useState({ name: "",user: "", pass: ""});
	const [errors, setErrors] = useState({error_name: "",error_user: "",error_pass: ""});
	const { isAuthenticated, loading } = useSelector((state) =>state.auth );
	const [signupLoading, setSignupLoading] = useState(false);
	const { register } = bindActionCreators( authActions, dispatch );

	const onInputChange = (e) => {
	    setState({
	      ...state,
	      [e.target.name]: e.target.value,
	    });
	};

	const onSubmitForm = async (e) => {
	    e.preventDefault();

	    const { name,user,pass } = state;  
	    let validationFlag = true;
	    let errUser = '';
	    let errPass = '';
	    let errName = '';

	    errName = checkUserValidation(name, "Name",true);
	    if (errName) {
	      validationFlag = false;
	      errors.error_name = errName;
	    }

	    errUser = checkUserValidation(user, "User",true);
	    if (errUser) {
	      validationFlag = false;
	      errors.error_user = errUser;
	    }

	    errPass = checkRequiredValidationWithMinMax(pass, "Password", 1, 25);
	    if (errPass) {
	      validationFlag = false;
	      errors.error_pass = errPass;
	    }

	    if (validationFlag === true) {
	      setSignupLoading(true);

	      const key = process.env.REACT_APP_TEST_KEY
	      const website_id = 1 
	      await register({'name' : name, 'user' : user, 'pass' : pass,"key" : key,"website_id" : website_id }).then((res) => {
	        setSignupLoading(false);
	        // navigate('/login');
	      });
	    } else {
	      setErrors({
	        error_user : errUser,
	        error_pass : errPass,
	        error_name : errName
	      });
	    }
	  };


    return (
    	<div className="wrapper">
	    	<Header/>
	    		<section class="home-search">
	                <div class="container">
	                    <h1>Sign Up</h1>
	                </div>
	            </section>

	            <section class="listing">
	                <div class="container">
	                    <h2>Create your account</h2>
	                    <div class="text-center"> 
	                    	<Form onSubmit={(e) => onSubmitForm(e)}>
	                            <div class="form-group">
	                                <Input 
				                        type="text" 
				                        className="form-control" 
				                        placeholder="Name"
				                        name="name"
				                        value={state.name}
				                        onChange={(e) => onInputChange(e)} 
			                        />
			                        {errors.error_name ? (
			                          <em
			                            id="user-error"
			                            className="error invalid-feedback"
			                          >
			                            {errors.error_name}
			                          </em>
			                        ) : null}
	                            </div>
	                            {/*<div class="form-group">
	                                <input type="text" class="form-control" placeholder="Email" />
	                            </div>*/}
	                            <div class="form-group">
	                                <Input 
				                        type="text" 
				                        className="form-control" 
				                        placeholder="Username"
				                        name="user"
				                        value={state.user}
				                        onChange={(e) => onInputChange(e)} 
			                        />
			                        {errors.error_user ? (
			                          <em
			                            id="user-error"
			                            className="error invalid-feedback"
			                          >
			                            {errors.error_user}
			                          </em>
			                        ) : null}
	                            </div>
	                            <div class="form-group">
	                                <Input 
				                        type="password" 
				                        className="form-control" 
				                        placeholder="Password"
				                        name="pass"
				                        value={state.password}
				                        onChange={(e) => onInputChange(e)} 
			                        />
			                        {errors.error_pass ? (
			                          <em
			                            id="pass-error"
			                            className="error invalid-feedback"
			                          >
			                            {errors.error_pass}
			                          </em>
			                        ) : null}
	                            </div> 
	                            <div class="form-group">
	                                <input type="submit" class="btn btn-default" value="Submit" /> 
	                            </div>
	                            <p>Already have an account? <Link to="/login"> Log in</Link></p>
	                            <p>By creating an account you agree to our  <br /><a href="#">Terms & Use</a> and <a href="#">Privacy Policy</a>.</p> 
	                        </Form>

	                    </div>
	                </div>
	            </section>

				 
			<Footer/>			
		</div>
	)

}

export default SignUp