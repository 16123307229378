import React, { Component }  from 'react';
import Header from './header'
import Footer from './footer'
import profile from '../assets/images/profile.svg'
import favorite from '../assets/images/favorite.svg'
import savedsearches from '../assets/images/saved-searches.svg'
import yourhome from '../assets/images/your-home.svg'
import notification from '../assets/images/notification.svg'
import messages from '../assets/images/messages.svg'
import logout from '../assets/images/logout.svg'
import menu from '../assets/images/menu.png'
import email from '../assets/images/email.svg'
import print from '../assets/images/print.svg'
import homeimage from '../assets/images/user-homeimg.png'
import Sidebar from './sidebar.js'

const YourHome = () => {
    return (
    	<div className="wrapper">
	    	<Header/>
	    	<section id="status_heaading" className="market-stats-top">
			    <div className="container">
			        <div className="row">
			            <div className="col-12">
			                <div className="media">
			                    <h3>Your Home</h3>
			                </div>
			            </div>
			        </div>
			    </div>
			</section>
			<section id="webpprofile">
				<Sidebar />
				<div className="content_area">
					<a className="btn btn-primary leftsidemenu" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
						<img src={menu} alt=""/>
					</a>
					<div className="content_searches">
						<div className="content_favourites">
							<div className="d-flex">
								<div className="printb">
									<a href="#" className="btn btn-secondary" >
										<img src={email} /> 
										<span>Email</span>
									</a>
									<a href="#" className="btn btn-secondary">
										<img src={print} /> 
										<span>Print</span>
									</a>
								</div>
							</div>
						</div>
						<div className="saved_your_search">
							<h2>Put your home here and find its value.</h2>
							<img src={homeimage} alt="" />
							<p>Keep up-to-date on your home's market value so you can track your<br/> investment and plan your next purchase.</p>
							<div className="input-group">
								<div className="locationS">
									<i className="fas fa-map-marker-alt"></i>
								</div>
								<input type="text" className="form-control" placeholder="Your address..." aria-label="Recipient's username" aria-describedby="basic-addon2"/>
								<div className="input-group-append">
								<span className="input-group-text" id="basic-addon2">GET STARTED</span>
							</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer/>			
		</div>
	)

}

export default YourHome