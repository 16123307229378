import React, { Component }  from 'react';
import YourHome from './components/your-home';
import WebProfile from './components/web-profile';
import Notification from './components/notification';
import Message from './components/message';
import SavedSearch from './components/saved-search';
import Favourite from './components/favourite';
import Login from './components/login';
import SignUp from './components/signup';
import InvalidDomain from './components/InvalidDomain';
import './assets/css/style.css'; 
import './assets/css/responsive.css'; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
// // Bootstrap CSS
// import "bootstrap/dist/css/bootstrap.min.css";
// // Bootstrap Bundle JS
// import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  return (
  	<Provider store={store}>
	  	<BrowserRouter>  
	      	<Routes>
	      		<Route exact path='/' element={<PrivateRoute/>}>
	            	<Route exact path='/' element={<WebProfile/>}/>
	          	</Route>
	          	<Route exact path='/your-home' element={<PrivateRoute/>}>
	            	<Route exact path='/your-home' element={<YourHome/>}/>
	          	</Route>
	          	<Route exact path='/notification' element={<PrivateRoute/>}>
	            	<Route exact path='/notification' element={<Notification/>}/>
	          	</Route>
	          	<Route exact path='/messages' element={<PrivateRoute/>}>
	            	<Route exact path='/messages' element={<Message/>}/>
	          	</Route>
	          	<Route exact path='/saved-search' element={<PrivateRoute/>}>
	            	<Route exact path='/saved-search' element={<SavedSearch/>}/>
	          	</Route>
	          	<Route exact path='/favourites' element={<PrivateRoute/>}>
	            	<Route exact path='/favourites' element={<Favourite/>}/>
	          	</Route>
				{/*<PrivateRoute path="/" element={<WebProfile />} />
				<PrivateRoute path="your-home" element={<YourHome />} />
				<PrivateRoute path="notification" element={<Notification />} />
				<PrivateRoute path="messages" element={<Message />} />
				<PrivateRoute path="saved-search" element={<SavedSearch />} />
				<PrivateRoute path="favourites" element={<Favourite />} />*/}

		      	<Route exact path='/login' element={<PublicRoute/>}>
	            	<Route exact path='/login' element={<Login/>}/>
	          	</Route>
	          	<Route exact path='/signup' element={<PublicRoute/>}>
	            	<Route exact path='/signup' element={<SignUp/>}/>
	          	</Route>
				{/*<PublicRoute path="login" element={<Login />} />
				<PublicRoute path="signup" element={<SignUp />} />*/}
		      	<Route path="invalid-domain" element={<InvalidDomain />} />
		    </Routes>
	    </BrowserRouter>
	</Provider>
  );
}

export default App;
