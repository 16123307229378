import React, { Component }  from 'react';
import logo from "../assets/images/logo.png";
import facebook from "../assets/images/facebook-icon.svg";
import linkedin from "../assets/images/linkedin-icon.svg";
import twitter from "../assets/images/twitter-icon.svg";
import instagram from "../assets/images/instagram-icon.svg";
import youtube from "../assets/images/youtube-icon.svg";
import mls from "../assets/images/mls-logo.svg";
import realtor from "../assets/images/realtor-icon.png";
import powered from "../assets/images/powered-img.png";
import { useSelector } from 'react-redux';

const Footer = () => {

    const { website_detail } = useSelector(state => state.auth )

    return (
        <footer id="footer" className="second-footer">
            <div className="footer-top">
                <div className="footer-logo">
                    <a href="#"><img src={website_detail.logo_url} alt="" className="img-fluid" /></a>
                </div>
                <div className="text-box">
                    <h3>{website_detail.company_name}</h3>
                    <p>{website_detail.description}</p>
                    <div className="media">
                        <strong>{website_detail.address}</strong>
                        <span>Office: <a href="#">{website_detail.phone}</a></span>
                        <span>Email: <a href="#">{website_detail.email}</a></span>
                    </div>
                </div>
                <div className="social-media">
                    <ul>
                        <li>
                            <a href={website_detail.facebook_url}><img src={facebook} alt="" /></a>
                        </li>
                        <li>
                            <a href={website_detail.linkedin_url}><img src={linkedin} alt="" /></a>
                        </li>
                        <li>
                            <a href={website_detail.twitter_url}><img src={twitter} alt="" /></a>
                        </li>
                        <li>
                            <a href={website_detail.instagram_url}><img src={instagram} alt="" /></a>
                        </li>
                        <li>
                            <a href={website_detail.youtube_url}><img src={youtube} alt="" /></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-link">
                    <ul>
                        <li>© 2021 {website_detail.company_name}. All Rights Reserved.</li>
                        <li><a href="#">Terms of Use</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Contact </a></li>
                    </ul>
                </div>
                <p>Listing data provided by Local Toronto through the TRREB IDX/VOW data license agreement and provided under copyright by the Toronto Real Estate Board. The listing data is deemed reliable but is not guaranteed accurate by the Toronto Real Estate Board nor by us.</p>
                <div className="media">
                    <div className="brand-logo">
                        <ul>
                            <li>
                                <a href="#"><img src={mls} alt="" className="img-fluid" /></a>
                            </li>
                            <li>
                                <a href="#"><img src={realtor} alt="" className="img-fluid" /></a>
                            </li>
                        </ul>
                    </div>
                    <p>The trademarks MLS®, Multiple Listing Service®, REALTOR®, REALTORS® and the REALTOR® logo are controlled by CREA and identify real estate professionals who are members of CREA.</p>
                </div>
                <div className="powered">
                    <p>Powered by <img src={powered}  alt="" /></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
