import React, { useEffect, useState } from "react";
import Header from './header'
import Footer from './footer'
import profile from '../assets/images/profile.svg'
import favorite from '../assets/images/favorite.svg'
import savedsearches from '../assets/images/saved-searches.svg'
import yourhome from '../assets/images/your-home.svg'
import notification from '../assets/images/notification.svg'
import messages from '../assets/images/messages.svg'
import logout from '../assets/images/logout.svg'
import menu from '../assets/images/menu.png'
import email from '../assets/images/email.svg'
import print from '../assets/images/print.svg'
import homeimage from '../assets/images/user-homeimg.png'
import eyeicon from '../assets/images/eyes-icon.svg'
import Sidebar from './sidebar.js'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  checkRequiredValidationWithMinMax,
  checkUserValidation,
} from "../Helpers/Validation";
import * as authActions from "../actions/authActions";
import  { Navigate,useNavigate } from 'react-router-dom'

import {
  Form,
  Input,
} from "reactstrap";

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [state, setState] = useState({ user: "", pass: ""});
	const [errors, setErrors] = useState({error_user: "",error_pass: ""});
	const { isAuthenticated, loading,domain_detail } = useSelector((state) =>state.auth );
	const [loginLoading, setLoginLoading] = useState(false);
	const { login } = bindActionCreators( authActions, dispatch );

	const onInputChange = (e) => {
	    setState({
	      ...state,
	      [e.target.name]: e.target.value,
	    });
	};

	const onSubmitForm = async (e) => {
	    e.preventDefault();

	    const { user,pass } = state;  
	    let validationFlag = true;
	    let errUser = '';
	    let errPass = '';

	    errUser = checkUserValidation(user, "User",true);
	    if (errUser) {
	      validationFlag = false;
	      errors.error_user = errUser;
	    }

	    errPass = checkRequiredValidationWithMinMax(pass, "Password", 1, 25);
	    if (errPass) {
	      validationFlag = false;
	      errors.error_pass = errPass;
	    }

	    if (validationFlag === true) {
	      setLoginLoading(true);

	      const key = process.env.REACT_APP_TEST_KEY 
	      const website_id = domain_detail.id ? domain_detail.id :  1;
	      await login({ 'user' : user, 'pass' : pass,"key" : key,"website_id" : website_id }).then((res) => {
	        setLoginLoading(false);
	        navigate('/');
	      });
	    } else {
	      setErrors({
	        error_user : errUser,
	        error_pass : errPass
	      });
	    }
	};
	

    return (
    	<div className="wrapper">
	    	<Header/>
	    		<section className="home-search">
	                <div className="container">
	                    <h1>Login</h1>
	                </div>
	            </section>

	            <section className="listing">
	                <div className="container">
	                    <h2>Log in your account</h2>
	                    <div className="text-center"> 
	                    	<Form onSubmit={(e) => onSubmitForm(e)}>
			                    <div className="form-group">
			                        <Input 
				                        type="text" 
				                        className="form-control" 
				                        placeholder="Username"
				                        name="user"
				                        value={state.user}
				                        onChange={(e) => onInputChange(e)} 
			                        />
			                        {errors.error_user ? (
			                          <em
			                            id="user-error"
			                            className="error invalid-feedback"
			                          >
			                            {errors.error_user}
			                          </em>
			                        ) : null}
			                    </div>
			                    <div className="form-group">			                        
			                        <Input 
				                        type="password" 
				                        className="form-control" 
				                        placeholder="Password"
				                        name="pass"
				                        value={state.password}
				                        onChange={(e) => onInputChange(e)} 
			                        />
			                        {errors.error_pass ? (
			                          <em
			                            id="pass-error"
			                            className="error invalid-feedback"
			                          >
			                            {errors.error_pass}
			                          </em>
			                        ) : null}

			                    </div>
			                    <div className="form-group">
			                        <ul>
			                            <li>
			                                {/*<label className="container-check">Keep me signed in
			                                    <Input type="checkbox" />
			                                    <span className="checkmark"></span> 
			                                </label>*/}
			                            </li>
			                            <li><a href="#" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalCenterForgot">Forgot password?</a></li>
			                        </ul>
			                    </div>
			                    <div className="form-group">
			                        <Input type="submit" className="btn btn-default" value="Submit" /> 
			                    </div>
			                    <p>Don't have an account yet? <Link to="/signup">Sign Up</Link></p>
			                </Form>

	                    </div>
	                </div>
	            </section>

				 
			<Footer/>			
		</div>
	)

}

export default Login