import React, { Component }  from 'react';
import Header from './header'
import Footer from './footer'
import profile from '../assets/images/profile.svg'
import favorite from '../assets/images/favorite.svg'
import savedsearches from '../assets/images/saved-searches.svg'
import yourhome from '../assets/images/your-home.svg'
import notification from '../assets/images/notification.svg'
import messages from '../assets/images/messages.svg'
import logout from '../assets/images/logout.svg'
import menu from '../assets/images/menu.png'
import email from '../assets/images/email.svg'
import print from '../assets/images/print.svg'
import homeimage from '../assets/images/user-homeimg.png'
import Sidebar from './sidebar.js'

const Notification = () => {
    return (
    	<div className="wrapper">
	    	<Header/>

	    	<section id="status_heaading" className="market-stats-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="media">
                                <h3>Notifications</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			<section id="webpprofile">
				<Sidebar />
				<div className="content_area">
					<a className="btn btn-primary leftsidemenu" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
						<img src={menu} alt="" />
					</a>
					<div className="content_notification">
						{/*<div className="alert alert-success alert-dismissible fade show" role="alert">
						  Changes updated successfully.
						  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						  </button>
						</div>*/}
						<div className="card">
							<div className="card-body">
								<p>Get notifications as per your preferences to stay on top of your journey of buying or selling a property. Turn them off any time you'd like.</p>
								<div className="text-right">
									<a href="#." className="btn btn-outline-info">Unsubscribe from all</a>
								</div>
								<h2>Listing Alerts</h2>
								<p>Receive new listing updates by email or SMS.</p>
								<h3>Email</h3>
								<div className="btn-box">
									<a href="#." className="btn btn-info">Yes</a>
									<a href="#." className="btn btn-outline-secondary">No</a>
								</div>
								<h3>SMS</h3>
								<div className="btn-box">
									<a href="#." className="btn btn-info">Yes</a>
									<a href="#." className="btn btn-outline-secondary">No</a>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-body">
								<h2>Monthly Housing Market Report</h2>
								<p>Receive monthly housing market trends by email.</p>
								<h3>Email</h3>
								<div className="btn-box">
									<a href="#." className="btn btn-info">Yes</a>
									<a href="#." className="btn btn-outline-secondary">No</a>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-body">
								<h2>Chat Messages</h2>
								<p>Receive chat messages by email or SMS.</p>
								<h3>Email</h3>
								<div className="btn-box">
									<a href="#." className="btn btn-info">Yes</a>
									<a href="#." className="btn btn-outline-secondary">No</a>
								</div>
								<h3>SMS</h3>
								<div className="btn-box">
									<a href="#." className="btn btn-info">Yes</a>
									<a href="#." className="btn btn-outline-secondary">No</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer/>			
		</div>
	)

}

export default Notification