import React, { Component }  from 'react';
import Header from './header'
import Footer from './footer'
import menu from '../assets/images/menu.png'
import user from '../assets/images/user.png'
import email from '../assets/images/email.svg'
import print from '../assets/images/print.svg'
import bell_icon from '../assets/images/bell-icon2.svg'
import down_icon from '../assets/images/down-icon2.svg'
import edit_icon from '../assets/images/edit-icon2.svg'
import delete_icon from '../assets/images/delete-icon2.svg'
import search_image from '../assets/images/user-searchimg.png'
import homeimage from '../assets/images/user-homeimg.png'
import Sidebar from './sidebar.js'

const SavedSearch = () => {
    return (
    	<div className="wrapper">
	    	<Header/>
	    	<section id="status_heaading" class="market-stats-top">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="media">
                                <h3>Saved Searches</h3>
								<span>
									<input type="text" class="form-control newsearch" placeholder="New Search" />
								</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			<section id="webpprofile">
				<Sidebar />
				<div class="content_area">
					<a class="btn btn-primary leftsidemenu" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
						<img src={menu} alt="" />
					</a>
					<div class="content_searches">
						<div class="card">
							<div class="card-body row">
								<div class="mysearchbox col-lg-2 col-md-3">
									<p>My Toronto Search</p>
									<a href="#." class="btn btn-info">345 Listings</a>
								</div>
								<div class="mysearchbox2 col-lg-5 col-md-3">
									<p>
										Status: Sale<br />
										Beds: 2+ | Baths: 3+ | Price: $450K - $1.2M | Size: sq. ft. 3500-5000 <br />
										Type: Condo Apt.
									</p>
								</div>
								<div class="mysearchbox3 col-lg-3 col-md-3">
									<div class="dropdown">
									  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										  <span class="box1">
										  	<img src={bell_icon} />
										  </span>
										  <span class="box2">Instant</span>
										  <span class="box3">
										  	<img src={down_icon} />
										  </span>
									  </button>
									  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
										<a class="dropdown-item" href="#">Daily</a>
										<a class="dropdown-item" href="#">Weekly</a>
										<a class="dropdown-item" href="#">Monthly</a>
										<a class="dropdown-item" href="#">Never</a>  
									  </div>
									</div>
								</div>
								<div class="mysearchbox4 col-lg-2 col-md-3">
									<a href="#.">
										<img src={edit_icon} />
										<span>Edit</span>
									</a>
									<a href="#." data-toggle="modal" data-target="#DeleteAccount2">
										<img src={delete_icon} />
										<span>Delete</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*<div class="content_area">
					<a class="btn btn-primary leftsidemenu" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
						<img src={menu} alt="" />
					</a>
					<div class="content_searches">
						<div class="saved_your_search">
							<h2>Save your searches here</h2>
							<img src={search_image} alt="" />
							<p>Saving your searches saves you time and it will let you know<br /> whenever a match comes in market.</p>
						</div>
					</div>
				</div>*/}
			</section>
			<Footer/>			
		</div>
	)

}

export default SavedSearch