import axios from 'axios'
import { toast } from 'react-toastify';

const config = {
  headers: {
    'Content-Type': 'application/json'
  },
}
const PROXY = process.env.REACT_APP_API_URL;

export const login = (loginData) => async(dispatch) =>{
	try {

    const body = loginData;
  	const res = await axios.post(PROXY + '/subscribers/login', body, config)

    if(res.data.response_code == 4){
      toast.error(res.data.info);
    }else{
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: res.data,
      })
      toast.success(res.data.info);
    }   
  } catch (error) {
    toast.error("Something went wrong, Please try again later!");
  }
}

export const register = (signUpData)  => async(dispatch) =>{

  try {
  	const body = signUpData;
  	const res = await axios.post(PROXY + '/subscribers/signup', body, config)
    

    if(res.data.response_code == 4){

      toast.error(res.data.info);

    }else{
      
      dispatch({
        type: 'REGISTER_SUCCESS',
        payload: res.data,
      })
      toast.success(res.data.info);
    }  

  } catch (error) {
    if (error.response !== undefined) {
      toast.error("Something went wrong, Please try again later!");
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const domainDetail = (domainDetail)  => async(dispatch) =>{
  const body = domainDetail;

  var queryString = Object.keys(body).map(function(key) {
      return key + '=' + body[key]
  }).join('&');
  try {
    const res = await axios.get(PROXY + '/websites/find_by_domain?'+queryString, config)

    if(res && res.data.data[0]){
      dispatch({
        type: 'GET_DOMAIN_DETAIL',
        payload: res.data.data[0],
      })
    }else{
      // toast.error("Invalid domain, Please contact administrator!");
    }
    
    return res.data.data[0];
  } catch (error) {
    // toast.error("Invalid domain, Please contact administrator!");
  }
}


export const websiteDetail = (websiteDetail)  => async(dispatch) =>{
  const body = websiteDetail;

  var queryString = Object.keys(body).map(function(key) {
      return key + '=' + body[key]
  }).join('&');

  try {
    const res = await axios.get(PROXY + '/websites/details?'+queryString, config)

    if(res && res.data.data[0]){
      dispatch({
        type: 'GET_WEBSITE_DETAIL',
        payload: res.data.data[0],
      })
    }else{
      // toast.error("Invalid domain, Please contact administrator!");
    }
    return res.data.data[0];
  } catch (error) {
    // toast.error("Invalid domain, Please contact administrator!");
  }
}

export const logout = () => async(dispatch) =>{
  try {

      dispatch({
        type: 'LOGOUT',
        payload: '',
      })
      toast.success('User logged out successfully');
  } catch (error) {
    toast.error("Something went wrong, Please try again later!");
  }
}
