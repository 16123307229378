import React, { Component }  from 'react';
import Header from './header'
import Footer from './footer'
import profile from '../assets/images/profile.svg'
import favorite from '../assets/images/favorite.svg'
import savedsearches from '../assets/images/saved-searches.svg'
import yourhome from '../assets/images/your-home.svg'
import notification from '../assets/images/notification.svg'
import messages from '../assets/images/messages.svg'
import logout from '../assets/images/logout.svg'
import attach from '../assets/images/attach.svg'
import attachment from '../assets/images/building-banner-img2.png'
import sendmessage from '../assets/images/sendmessage.svg'
import menu from '../assets/images/menu.png'
import optionicon from '../assets/images/option-icon.svg'
import user from '../assets/images/user.png'
import email from '../assets/images/email.svg'
import print from '../assets/images/print.svg'
import homeimage from '../assets/images/user-homeimg.png'
import Sidebar from './sidebar.js'

const Message = () => {
    return (
    	<div className="wrapper">
	    	<Header/>
	    	<section id="status_heaading" class="market-stats-top">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="media">
                                <h3>Messages</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			<section id="webpprofile">
				<Sidebar />
				<div class="content_area">
					<a class="btn btn-primary leftsidemenu" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
						<img src={menu} alt="" />
					</a>
					<div class="content_messages">
						<div class="card">
							<div class="card-body">
								<div class="chat_name_row">
									<div class="d-flex align-items-center py-1">
										<div class="position-relative">
											<img src={user} class="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40" />
										</div>
										<div class="flex-grow-1 pl-1 pt-1">
											<h2>John Doe</h2>
											<p>Seen 20 mins ago</p>
										</div>
									</div>
								</div>
								<p class="today">Today</p>
								<div class="chat_main">
									<div class="chat-text_blue">
										<p>
											10.22AM 
											<span class="position-relative">
												<img src={optionicon} alt="" />
												<span class="edit_hover">
													<a href="#.">Edit</a>
													<a href="#.">Delete</a>
												</span>
											</span>
										</p>
										<h2>I like this property. What do you think?</h2>
										<div class="d-flex align-items-center py-1">
											<div class="position-relative">
												<img src={attachment} alt="" class="mr-1" width="99" height="72" />
											</div>
											<div class="flex-grow-1 pl-1 pt-1">
												<h3>Minto Plaza</h3>
												<p>Type: High-rise ! Dccupancy: 2024</p>
												<h4>38 Elm St. Toronto</h4>
											</div>
										</div>
									</div>
									<div class="chat-gray">
										<p>John, 10.27 AM</p>
										<h2>Looks Great! Would you like me to put in an offer?</h2>
									</div>
									<div class="chat-text_blue">
										<p>
											10.22AM 
											<span class="position-relative">
												<img src={optionicon} alt="" />
												<span class="edit_hover">
													<a href="#.">Edit</a>
													<a href="#.">Delete</a>
												</span>
											</span>
										</p>
										<h2>Sure. Go ahead.</h2>
									</div>
								</div>
								<div class="chat_sending">
									<input type="text" placeholder="Write here..." class="form-control" />
									<span class="attached">
									<a href="#.">
										<img src={attach} alt="" />
									</a>
								</span>
								<span class="sendingbtn">
									<a href="#.">
										<img src={sendmessage} alt="" />
									</a>
								</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer/>			
		</div>
	)

}

export default Message