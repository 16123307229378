import React, { Component }  from 'react';
import Header from './header'
import Footer from './footer'
import menu from '../assets/images/menu.png'
import user from '../assets/images/user.png'
import email from '../assets/images/email.svg'
import print from '../assets/images/print.svg'
import lilstar from '../assets/images/lilstar.svg'
import star_icon from '../assets/images/star-icon2.svg'
import edit_icon from '../assets/images/edit-icon2.svg'
import delete_icon from '../assets/images/delete-icon2.svg'
import linkicon from '../assets/images/link.svg'
import messageicon from '../assets/images/message.svg'
import shareicon from '../assets/images/share.svg'
import search_image from '../assets/images/user-searchimg.png'
import properties from '../assets/images/properties.png'
import Sidebar from './sidebar.js'
import listingpic from '../assets/images/listingpic.png'

const Favourite = () => {
    return (
    	<div className="wrapper">
	    	<Header/>
	    	<section id="status_heaading" class="market-stats-top">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="media">
                                <h3>Favourites</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			<section id="webpprofile">
				<Sidebar />
				{/*<div class="content_area">
					<a class="btn btn-primary leftsidemenu" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
						<img src={menu} alt="" />
					</a>
					<div class="content_favourites">
						<div class="d-flex">
							<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
								<li class="nav-item" role="presentation">
									<a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Listings</a>
								</li>
								<li class="nav-item" role="presentation">
									<a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Buildings</a>
								</li>
								<li class="nav-item" role="presentation">
									<a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">New constructions</a>
								</li>
							</ul>
							<div class="printb">
								<a href="#." class="btn btn-secondary" >
									<img src={email} />
									<span>Email</span>
								</a>
								<a href="#." class="btn btn-secondary">
									<img src={print} />
									<span>Print</span>
								</a>
							</div>
						</div>
						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
								<div class="save_your">
									<h2>Save your favourite properties here.</h2>
									<img src={properties} alt="" />
									<p>Whenever you find a property you like, select the <img src={star_icon} /> to save them here.</p>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
								<div class="save_your">
									<h2>Save your favourite properties here.</h2>
									<img src={properties} alt="" />
									<p>Whenever you find a property you like, select the <img src={star_icon} /> to save them here.</p>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
								<div class="save_your">
									<h2>Save your favourite properties here.</h2>
									<img src={properties} alt="" />
									<p>Whenever you find a property you like, select the <img src={star_icon} /> to save them here.</p>
								</div>
							</div>
						</div>
					</div>
				</div>*/}
				<div class="content_area">
					<a class="btn btn-primary leftsidemenu" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
						<img src={menu} alt="" />
					</a>
					<div class="content_favourites">
						<div class="d-flex">
							<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
								<li class="nav-item" role="presentation">
									<a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Listings</a>
								</li>
								<li class="nav-item" role="presentation">
									<a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Buildings</a>
								</li>
								<li class="nav-item" role="presentation">
									<a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">New constructions</a>
								</li>
							</ul>
							<div class="printb">
								<a href="#." class="btn btn-secondary" >
									<img src={email} />
									<span>Email</span>
								</a>
								<a href="#." class="btn btn-secondary">
									<img src={print} />
									<span>Print</span>
								</a>
							</div>
						</div>
						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
								<label class="cleartext" id="checkAll"><input type="checkbox" name="checkedAll" id="checkedAll" hidden />
									<span class="selecttext2">Select all</span>
									<span class="selecttext">Clear All</span>
								</label>
								<div class="pro_listing_main">
									<div class="row">
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="justlisted">Just Listed</div>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>$ 599,000 <span>5 hrs ago</span></h3>
												<h4>BD 2+1 | BA 2 | Garage 2 | Sq. Ft. 3,500</h4>
												<p>151 Green Village W, Suite 304, Toronto</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="justlisted">Featured</div>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>$ 599,000 <span>5 hrs ago</span></h3>
												<h4>BD 2+1 | BA 2 | Garage 2 | Sq. Ft. 3,500</h4>
												<p>151 Green Village W, Suite 304, Toronto</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>$ 599,000 <span>5 hrs ago</span></h3>
												<h4>BD 2+1 | BA 2 | Garage 2 | Sq. Ft. 3,500</h4>
												<p>151 Green Village W, Suite 304, Toronto</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="justlisted">Price changed</div>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>$ 599,000 <span>5 hrs ago</span></h3>
												<h4>BD 2+1 | BA 2 | Garage 2 | Sq. Ft. 3,500</h4>
												<p>151 Green Village W, Suite 304, Toronto</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
								<label class="cleartext" id="checkAll">
									<input type="checkbox" name="checkedAll2" id="checkedAll2" hidden />
									<span class="selecttext2">Select all</span>
									<span class="selecttext">Clear All</span>
								</label>
								<div class="pro_listing_main">
									<div class="row">
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>Horizon on Bay</h3>
												<h4>For Sale 4 | For Rent 3</h4>
												<p>633 Bay St.</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												 <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>Horizon on Bay</h3>
												<h4>For Sale 4 | For Rent 3</h4>
												<p>633 Bay St.</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>Horizon on Bay</h3>
												<h4>For Sale 4 | For Rent 3</h4>
												<p>633 Bay St.</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>Horizon on Bay</h3>
												<h4>For Sale 4 | For Rent 3</h4>
												<p>633 Bay St.</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
								<label class="cleartext"><input type="checkbox" name="checkedAll3" id="checkedAll3" hidden /> 
									<span class="selecttext2">Select all</span>
									<span class="selecttext">Clear All</span>
								</label>
								<div class="pro_listing_main">
									<div class="row">
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="liststar"><a href="#."><i class="fas fa-star"></i></a></div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>Minto Plaza</h3>
												<h4>Type: High-Rise | Occupancy: 2024</h4>
												<p>38 Elm St. Toronto</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>Minto Plaza</h3>
												<h4>Type: High-Rise | Occupancy: 2024</h4>
												<p>38 Elm St. Toronto</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>Minto Plaza</h3>
												<h4>Type: High-Rise | Occupancy: 2024</h4>
												<p>38 Elm St. Toronto</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6">
											<div class="pro_listing_img">
												<a href="#."><img src={listingpic} alt="" /></a>
												<div class="liststar">
													<a href="#.">
														<img src={lilstar} />
													</a>
												</div>
												<label class="listcheck">
												  <input type="checkbox" name="checkAll" class="checkSingle" />
												  <span class="checkmark"></span>
												</label>
											</div>
											<div class="pro_listing_text">
												<h3>Minto Plaza</h3>
												<h4>Type: High-Rise | Occupancy: 2024</h4>
												<p>38 Elm St. Toronto</p>
												<div class="pro_list_icon">
													<span>
														<a href="#." class="btn btn-primary">
															<img src={linkicon} /> 
														</a>
														<span class="sahre_dropdown text-center copy">
															<a href="#.">Copy link</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary" data-toggle="modal" data-target="#chatModal">
															<img src={messageicon} />
														</a>
														<span class="sahre_dropdown agent">
															<a href="#.">Chat with agent</a>
														</span>
													</span>
													<span>
														<a href="#." class="btn btn-primary">
															<img src={shareicon} />
														</a>
														<span class="sahre_dropdown">
															<a href="#.">Facebook</a>
															<a href="#.">Twitter</a>
															<a href="#.">LinkedIn</a>
														</span>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer/>			
		</div>
	)

}

export default Favourite