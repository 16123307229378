import React, { Component }  from 'react';
import Header from './header'
import Footer from './footer'
import profile from '../assets/images/profile.svg'
import favorite from '../assets/images/favorite.svg'
import savedsearches from '../assets/images/saved-searches.svg'
import yourhome from '../assets/images/your-home.svg'
import notification from '../assets/images/notification.svg'
import messages from '../assets/images/messages.svg'
import logout from '../assets/images/logout.svg'
import menu from '../assets/images/menu.png'
import email from '../assets/images/email.svg'
import print from '../assets/images/print.svg'
import homeimage from '../assets/images/user-homeimg.png'
import Sidebar from './sidebar.js'

const WebProfile = () => {
    return (
    	<div className="wrapper">
	    	<Header/>

	    	<div className="modal fade" id="DeleteAccount" tabindex="-1" aria-labelledby="DeleteAccountLabel" aria-hidden="true">
			  <div className="modal-dialog modal-dialog-centered">
			    <div className="modal-content">
			      <div className="modal-body">
					<h2>Delete Account</h2>
					<p>We are sorry to see you go. Are you sure you want to delete your account?</p>  
					<p>Please keep in mind that account deletion is final. All your saved searches, favourites, notifications will be lost. There is no way to restore your account.</p>    
			        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>
			        <button type="button" className="btn btn-danger">Delete my account</button>
			      </div>
			    </div>
			  </div>
			</div>

	    	<section id="status_heaading" className="market-stats-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="media">
                                <h3>Profile</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			<section id="webpprofile">
				<Sidebar />
				<div className="content_area">
					<a className="btn btn-primary leftsidemenu" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
						<img src={menu} alt="" />
					</a>
					<div className="content_web_profile">
						<div className="card innerfocus1">
							<div className="card-body">
								<form>
									<h2>Account Info</h2>
									<div className="row">
										<div className="col-md-6">
											<input type="text" className="form-control" placeholder="John" />
										</div>
										<div className="col-md-6">
											<input type="text" className="form-control" placeholder="Doe" />
										</div>
										<div className="col-md-6">
											<input type="text" className="form-control" placeholder="johndoe@gmail.com" />
										</div>
										<div className="col-md-6">
											<input type="text" className="form-control" placeholder="+1 6470000000" />
										</div>
									</div>
									<div className="btn_box">
										<a href="#." className="btn btn-secondary">Save</a>
									</div>
								</form>
							</div>
						</div>
						<div className="card innerfocus2">
							<div className="card-body">
								<form>
									<h2>Change Password</h2>
									<div className="row">
										<div className="col-md-12">
											<input type="password" className="form-control" placeholder="Current password" />
										</div>
										<div className="col-md-6">
											<input type="text" className="form-control" placeholder="New password" />
										</div>
										<div className="col-md-6">
											<input type="text" className="form-control" placeholder="Confirm password" />
										</div>
									</div>
									<div className="btn_box">
										<a href="#." className="btn btn-secondary">Save</a>
									</div>
									<p className="note">If you forgot your password, please <a href="#.">log out</a> and use "Forgot Password" option on the login window.</p>
								</form>
							</div>
						</div>
						<div className="card">
							<div className="card-body">
								<form>
									<h2>Delete Account</h2>
									<p className="note">Once you delete your account, it cannot be reactivated.</p>
									<div className="btn_box">
										<a href="#." className="btn btn-danger" data-toggle="modal" data-target="#DeleteAccount">Delete my account</a>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer/>			
		</div>
	)

}

export default WebProfile