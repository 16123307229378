import React, { useEffect, useState } from "react";
import Header from './header'
import Footer from './footer'

const InvalidDomain = () => {
	

    return (
    	<div className="wrapper">
	    	<Header/>
	    		<section className="home-search">
	                <div className="container">
	                </div>
	            </section>

	            <section className="listing">
	                <div className="container">
	                    <h2>Invalid domain, Please contact administrator!</h2>	               
	                </div>
	            </section>				 
			<Footer/>			
		</div>
	)

}

export default InvalidDomain