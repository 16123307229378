const initialState = {
  token: localStorage.getItem('tokenAdmin'),
  isAuthenticated: false,
  loading: true,
  user: null,
  domain_detail: {},
  website_detail: {},
}


const authReducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOGIN_SUCCESS':
	     	localStorage.setItem('tokenAdmin', payload.token)
		    return {
		        ...state,
		        user : payload,
		        isAuthenticated: true,
		        loading: false,
		    }
	    case 'LOGOUT':
		    localStorage.removeItem('tokenAdmin')
		    return {
		        ...state,
		        isAuthenticated: false,
		        loading: false,
		        user: null,
		    } 
		case 'GET_DOMAIN_DETAIL':
		    return {
		        ...state,
		        domain_detail : payload
		    }

		case 'GET_WEBSITE_DETAIL':
		    return {
		        ...state,
		        website_detail : payload
		    }
		default:
      		return state
	}
}

export default authReducer