import React, { Component,useState,useEffect }  from 'react';
import cellphone from "../assets/images/cell-phone-icon.svg"
import emailicon from "../assets/images/email-icon.svg"
import logo from "../assets/images/logo.png"
import search from "../assets/images/search-icon2.svg"
import user from "../assets/images/user.png"
import { useSelector,useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from "../actions/authActions";
import facebook from "../assets/images/facebook-icon.svg";
import linkedin from "../assets/images/linkedin-icon.svg";
import twitter from "../assets/images/twitter-icon.svg";
import instagram from "../assets/images/instagram-icon.svg";
import youtube from "../assets/images/youtube-icon.svg";
import  { Navigate,useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    // const { isAuthenticated } = useSelector(state => state.auth )
    const [isDetailLoad, setIsDetailLoad] = useState(false);
    const [website_detail, setWebsiteDetail] = useState({});

    const { domainDetail,websiteDetail } = bindActionCreators(
        authActions,
        dispatch
    );
    const tokenAdmin = localStorage.getItem('tokenAdmin')
    useEffect(()=>{
        if(!isDetailLoad){
            const key = process.env.REACT_APP_TEST_KEY;
            // const domainName = window.location.hostname == 'localhost' ? 'aerysys.com' :  window.location.hostname ;
            const domainName ='aerysys.com' ;
            domainDetail({'domain' : domainName, 'key' : key  }).then((res) => {
                if(res && res.id){
                    websiteDetail({'id' : res.id, 'key' : key  }).then((wb) => {
                        setWebsiteDetail(wb)
                    });
                }else{
                    navigate('/invalid-domain');
                }
                setIsDetailLoad(true);
            });
        }  
    },[]);

    return (
        <header>
            <div className="top-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                        <div className="social-media ">
                            <ul className="social_icons header_social_icons">
                                <li>
                                    <a href={website_detail.facebook_url}><img src={facebook} alt="" /></a>
                                </li>
                                <li>
                                    <a href={website_detail.linkedin_url}><img src={linkedin} alt="" /></a>
                                </li>
                                <li>
                                    <a href={website_detail.twitter_url}><img src={twitter} alt="" /></a>
                                </li>
                                <li>
                                    <a href={website_detail.instagram_url}><img src={instagram} alt="" /></a>
                                </li>
                                <li>
                                    <a href={website_detail.youtube_url}><img src={youtube} alt="" /></a>
                                </li>
                            </ul>
                        </div>
                            <div className="socail-detail">
                                <ul>
                                    <li><img src={cellphone} alt="" /><a href="#">{website_detail.phone}</a></li>
                                    <li><img src={emailicon} alt="" /><a href="#">{website_detail.email}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="#"><img src={website_detail.logo_url} alt="" /><span>{website_detail.company_name}</span></a> 
                                <div className="media">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                        <span className="navbar-toggler-icon"></span>
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <a className="search-btn d-block d-lg-none" href="#"><img src={search} alt=""  /></a>
                                </div>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    { tokenAdmin ?  <ul className="navbar-nav m-auto">
                                        
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Home</a>
                                        </li>
                                        <li className="nav-item active">
                                            <a className="nav-link" href="#">Buy</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Sell</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Search</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Neighbourhood</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Blog</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">About</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Contact</a>
                                        </li>
                                        <li className="nav-item d-none d-lg-block">
                                            <a className="nav-link search-btn" href="#"><img src={search} alt=""  /></a>
                                        </li>
                                       
                                    </ul>  : <ul className="navbar-nav m-auto"></ul>  }
                                    { tokenAdmin ? <div className="header-right"> 
                                        <div className="login-detail">
                                            <ul>
                                                <li className="dropdown"><a href="#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="mr-2" style={{ width: "30px", height: "30px" }} src={user} alt="" />Account</a>
        											<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        												<a className="dropdown-item" href="#">Profile</a>
        												<a className="dropdown-item" href="#">Favourites</a>
        												<a className="dropdown-item" href="#">Saved Searches</a>
        												<a className="dropdown-item" href="#">Messages <span className="ml-3 badge badge-danger">3</span></a>
        												<a className="dropdown-item" href="#">Log Out</a>
        											</div>
        										</li>
                                            </ul>
                                        </div>
                                    </div> : null } 
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header